import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"

const IndexPage = () => (
  <Layout title="Our Beliefs">
    <SEO title="Our Beliefs" />
    <section>
      <div className="container">
        <div className="columns is-8 is-variable">
          <div className="column page-content">
            <p>
            <strong>The Common Faith</strong>
              <br></br>These are our core beliefs and we believe that based on these truths as our foundation we believers can come together to have sweet fellowship to enjoy our dear Lord Jesus Christ. We hold the faith which is common to all believers.
              <blockquote><strong>Titus 1:4 — </strong><i>"To Titus, genuine child according to the <strong>common faith</strong>: Grace and peace from God the Father and Christ Jesus our Savior."</i></blockquote>
            </p>
            <p>
              <strong>The Common Salvation</strong>
              <br></br>Not subjective faith as our believing but objective faith as our belief, referring to the things we believe in, the contents of the New Testament as our faith, in which we believe for our common salvation. 
              <blockquote><strong>Jude 3 — </strong><i>"Beloved, while using all diligence to write to you concerning our <strong>common salvation</strong>, I found it necessary to write to you and exhort you to earnestly contend for <strong>the faith</strong> once for all delivered to the saints."</i></blockquote>
            </p>
            <p>
              <strong>The Bible</strong>
              <br></br>The Bible is the complete divine revelation inspired word by word by God through the Holy Spirit.
              <blockquote><strong>2 Peter 1:21 — </strong><i>"For no prophecy was ever borne by the will of man, but men spoke from God while being borne by the Holy Spirit."</i></blockquote>
              <blockquote><strong>2 Timothy 3:16 — </strong><i>"All Scripture is God-breathed and profitable for teaching, for conviction, for correction, for instruction in righteousness,"</i></blockquote>
            </p>
            <p>
              <strong>The Triune God</strong>
              <br></br>God is uniquely one, yet a Trinity—the Father, the Son, and the Spirit.
              <blockquote><strong>1 Timothy 2:5 — </strong><i>"For there is one God and one Mediator of God and men, the man Christ Jesus,"</i></blockquote>
              <blockquote><strong>Matthew 28:19 — </strong><i>"Go therefore and disciple all the nations, baptizing them into the name of the Father and of the Son and of the Holy Spirit,"</i></blockquote>
            </p>
            <p>
              <strong>Jesus Christ</strong>
              <br></br>The Son of God, even God Himself, was incarnated to be a man by the name of Jesus Christ.
              <blockquote><strong>John 1:1 — </strong><i>"In the beginning was the Word, and the Word was with God, and the Word was God."</i></blockquote>
              <blockquote><strong>John 1:14 — </strong><i>"And the Word became flesh and tabernacled among us (and we beheld His glory, glory as of the only Begotten from the Father), full of grace and reality."</i></blockquote>
            </p>
            <p>
              <strong>Christ’s Crucifixion</strong>
              <br></br>Christ died on the cross for our sins, shedding His blood for our redemption.
              <blockquote><strong>1 Peter 2:24 — </strong><i>"Who Himself bore up our sins in His body on the tree, in order that we, having died to sins, might live to righteousness; by whose bruise you were healed."</i></blockquote>
              <blockquote><strong>Ephesians 1:7 — </strong><i>"In whom we have redemption through His blood, the forgiveness of offenses, according to the riches of His grace,"</i></blockquote>
              </p>
            <p>
              <strong>Christ's Resurrection</strong>
              <br></br>Christ resurrected from among the dead on the third day.
              <blockquote><strong>1 Corinthians 15:4 — </strong><i>"And that He was buried, and that He has been raised on the third day according to the Scriptures;"</i></blockquote>
            </p>
            <p>
            <strong>Christ's Ascension</strong> 
              <br></br>Christ ascended to the right hand of God to be Lord of all.
              <blockquote><strong>Acts 1:9 — </strong><i>"And when He had said these things, while they were looking on, He was lifted up, and a cloud took Him away from their sight."</i></blockquote>
              <blockquote><strong>Acts 2:33 — </strong><i>"Therefore having been exalted to the right hand of God and having received the promise of the Holy Spirit from the Father, He has poured out this which you both see and hear."</i></blockquote>
              <blockquote><strong>Acts 2:36 — </strong><i>"Therefore let all the house of Israel know assuredly that God has made Him both Lord and Christ, this Jesus whom you have crucified."</i></blockquote>
            </p>
            <p>
            <strong>Regeneration</strong>
              <br></br> Whenever any person repents to God and believes in the Lord Jesus Christ, he or she is regenerated (born again) and becomes a living member of the one Body of Christ.
              <blockquote><strong>Acts 20:21 — </strong><i>"Solemnly testifying both to Jews and to Greeks repentance unto God and faith in our Lord Jesus."</i></blockquote>
              <blockquote><strong>John 3:3 — </strong><i>"Jesus answered and said to him, Truly, truly, I say to you, Unless one is born anew, he cannot see the kingdom of God."</i></blockquote>
              <blockquote><strong>Ephesians 1:22-23 — </strong><i>"And He subjected all things under His feet and gave Him to be Head over all things to the church, Which is His Body, the fullness of the One who fills all in all."</i></blockquote>
              <blockquote><strong>Romans 12:5 — </strong><i>"So we who are many are one Body in Christ, and individually members one of another."</i></blockquote>
            </p>
            <p>
            <strong>Christ's Return</strong> 
              <br></br>Christ is coming again to receive His believers to Himself.
              <blockquote><strong>1 Thessalonians 2:19 — </strong><i>“For what is our hope or joy or crown of boasting before our Lord Jesus at His coming? Are not even you?“</i></blockquote>
            </p>
            
            {/*<p>We hold the faith which is common to all the believers (Titus 1:4, Jude 3):</p>
            <p>The Bible is the complete divine revelation inspired word by word by God through the Holy Spirit (2 Pet. 1:21, 2 Tim. 3:16)</p>
            <p>God is uniquely one, yet triune — the Father, the Son, and the Spirit (1 Tim. 2:5a, Matt. 28:19)</p>
            <p>The Son of God, even God Himself, was incarnated to be a man by the name of Jesus Christ (John 1:1, John 1:14)</p>
            <p>Christ died on the cross for our sins, shedding His blood for our redemption (1 Pet. 2:24, Eph. 1:7a)</p>
            <p>Christ resurrected from among the dead on the third day (1 Cor. 15:4)</p>
            <p>Christ ascended to the right hand of God to be Lord of all (Acts 1:9, Acts 2:33, Acts 2:36)</p>
            <p>Whenever any person repents to God and believes in the Lord Jesus Christ, he or she is regenerated (born again) and becomes a living member of the one Body of Christ.</p>
            <p>Christ is coming again to receive His believers to Himself.</p>
            */}
            
            <p>All verses are quoted from the <a href="https://www.recoveryversion.bible/" target="_blank" rel="noopener noreferrer">Holy Bible Recovery Version</a>. You can order a free copy of the New Testament Recovery Version <a href="http://biblesforamerica.org/free-bible/" target="_blank" rel="noopener noreferrer">here.</a></p>
        </div>
      </div>
    </div>
  </section>
  <style jsx>{`
    .page-content {
      font-size: 1.3em;
      line-height: 1.4em;
    }
    .page-content p {
      font-family: Georgia, Times New Roman, serif;
      margin-bottom: 1.5em;
    }
    blockquote {
      font-size: .80em;
      line-height: 1.5em;
      display: block;
      margin-top: 0em;
      margin-bottom: 0em;
      margin-left: 30px;
      margin-right: 75px;
    }
  `}</style>
</Layout>
)
export default IndexPage